import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { message, Spin } from 'antd';

message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
    rtl: true,
});
const BASE_URL = process.env.REACT_APP_API+'/v1';
// const BASE_URL = 'http://192.168.2.36:9999/v1';

let defaultConfig = {
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        // 'Authorization': '',
        // 'locale': ''
    }
};
let instance = axios.create(defaultConfig);

let requestCount = 0;

function showLoading() {
    if(requestCount === 0) {
        var dom = document.createElement('div');
        dom.setAttribute('id', 'loading');
        document.body.appendChild(dom);
        ReactDOM.render(<Spin tip=""  size="large"/>, dom)
    }
    requestCount++;
}
function hideLoading () {
    setTimeout(() => {
        requestCount--
        if (requestCount === 0) {
            document.body.removeChild(document.getElementById('loading'))
        }
    }, 0);
}
function handleError(error, vc) {
    //是否传递了vue component
    if (vc) {
        vc.loading = false;
    }
    if (axios.isCancel(error)) { // 如果是用户主动取消的
        return;
    }
    if (error.response) { // 服务器错误
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        message.error({
            content: error.response.data.message || '服务端错误',
        });
    } else if (error.request) {
        message.error({
            content: '请求失败',
        });
    } else {
        message.error({
            content: '请求失败',
        });
    }
}

const http = {
    getBaseURL: () => {
        return defaultConfig.baseURL;
    },
    request: (params, config) => {
        if (!params || Object.prototype.toString.call(params) !== "[object Object]") {
            throw new Error("params is undefined or not an object")
        }

        return new Promise((resolve, reject) => {
            instance.request(params).then(res => {
                if (!config || config.isLoading !== false) {
                    hideLoading()
                }
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    handleError(res, params.vc);
                    reject(res);
                }
            }).catch(error => {
                if (!config || config.isLoading !== false) {
                    hideLoading()
                }
                handleError(error, params.vc);
                reject(error);
            });
        });
    },
    getConfig: (method, url, data, config) => {
        let params = {
            url: url,
            method: method
        };
        if (method === 'get') {
            data && (params.params = data);
        } else {
            data && (params.data = data);
        }
        //没有传递authApi参数都是私有接口
        config && Object.assign(params, config);
        return params;
    },
    get: (url, data, config) => {
        if (!config || config.isLoading !== false) {
            showLoading()
        }
        let params = http.getConfig('get', url, data, config);
        return http.request(params, config);
    },
    post: (url, data, config) => {
        if (!config || config.isLoading !== false) {
                showLoading()
        }
        let params = http.getConfig('post', url, data, config);
        return http.request(params, config);
    }
};

export default http;