import { HashRouter as Router, Route, Switch } from "react-router-dom";
import React from 'react';
import { withRouter } from "react-router-dom";
import './App.css';
import News from './News';
import Notice from './Notice';

function App() {


  return (
      <Router>
        <Switch>
          <Route exact path="/news" component={News} />
          <Route exact path="/notice" component={Notice} />
        </Switch>
      </Router>
  );
}

export default withRouter(App);


