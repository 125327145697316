export function localTime(str) {
    if (!str) return '--';
    let date;
    if (str.indexOf('T') !== -1) { // date格式的字符串
        date = new Date(str);
    } else {
        // ios 无法识别 'YYYY-MM-DD HH:mm:ss' - ，转为 /
        date = new Date(str.replace(/-/g, "/"));
    }
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');
    let hour = (date.getHours() + '').padStart(2, '0');
    let minute = (date.getMinutes() + '').padStart(2, '0');
    let second = (date.getSeconds() + '').padStart(2, '0');

    return date.getFullYear() + "-" + month + "-" + day + " " +  hour + ":" + minute + ":" + second;
}

// 上传的图片为私有访问需要调用接口获取权限

export function getImgUrl(url) {
    return process.env.REACT_APP_IMG_URL+url
}