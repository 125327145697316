import React from "react";
import http from "./lib/service/http";
import { localTime } from "./lib/help/helper";

export default class Notice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseData: {},
      userName: "",
    };
  }

  componentDidMount() {
    this.getInfoData();
    document.title = "系统公告";
  }

  componentWillUnmount() {}

  getInfoData() {
    const search = new URLSearchParams(this.props.location.search);
    const id = search.get("id");
    http
      .get("/system-notification/detail/" + id, null, { isLoading: true })
      .then((res) => {
        this.setState({
          responseData: res.data.system_notification_obj,
          userName: res.data.username,
        });
      });
  }

  render() {
    const { responseData, userName } = this.state;
    return (
      <div className="news-info-content">
        <div className="news-info-box">
          <div className="info-title">{responseData.Title}</div>
          <div className="list-info">
            <div className={"info-text publisher"}>{userName}</div>
            <div className="info-text publish-time">
              {localTime(responseData.PublishedAt)}
            </div>
          </div>
          <div className="info-body">
            <div
              className="info-content w-e-text w-e-toolbar"
              dangerouslySetInnerHTML={{ __html: responseData.Content }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
